import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

// Firebase configs
const firebaseConfig = {
  apiKey: "AIzaSyBcH7m5KNFaYz2KWDUk1Q8217pWDpw1YXE",
  authDomain: "diji-vatandas.firebaseapp.com",
  databaseURL: "https://diji-vatandas.firebaseio.com",
  projectId: "diji-vatandas",
  storageBucket: "diji-vatandas.appspot.com",
  messagingSenderId: "429390470984",
  appId: "1:429390470984:web:7154d5a1cf133e8cea1d7d",
  measurementId: "G-6DGBPKDGG2"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
