import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import store from "@/store";
//import firebase from "@/plugins/firebase";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () => import("../layouts/tr/home/Index.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("../views/tr/home/Index.vue")
      },
      {
        path: "hakkimizda",
        name: "about-us",
        component: () => import("../views/tr/about-us/Index.vue")
      },
      {
        path: "egitim-katalogu",
        name: "course-catalog",
        component: () => import("../views/tr/course-catalog/Index.vue")
      },
      {
        path: "egitim-katalogu/:courseUrlCode",
        name: "course-catalog-detail",
        component: () => import("../views/tr/course-catalog/Detail.vue")
      },
      {
        path: "/kullanim-sartlari",
        name: "terms-of-use",
        component: () => import("../views/TermsOfUse.vue")
      },
      {
        path: "/gizlilik-politikasi",
        name: "privacy-policy",
        component: () => import("../views/PrivacyPolicy.vue")
      },
      {
        path: "/kisisel-verilerin-korunmasi",
        name: "personal-data-protection",
        component: () => import("../views/PersonalDataProtection.vue")
      }
    ]
  },
  {
    path: "",
    component: () => import("../layouts/tr/account/Index.vue"),
    children: [
      {
        path: "/kaydol",
        name: "sign-up",
        component: () => import("../views/tr/account/SignUp.vue")
      },
      {
        path: "/giris",
        name: "sign-in",
        component: () => import("../views/tr/account/SignIn.vue")
      },
      {
        path: "/parolami-unuttum",
        name: "forgot-password",
        component: () => import("../views/tr/account/ForgotPassword.vue")
      }
    ]
  },
  {
    path: "/ogrenen",
    component: () => import("../layouts/tr/learner/Index.vue"),
    children: [
      {
        path: "egitimlerim",
        name: "learner-home",
        component: () => import("../views/tr/learner/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ayarlar",
        name: "learner-settings",
        component: () => import("../views/tr/learner/Settings.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/egitimler/:courseUrlCode",
    component: () => import("../layouts/tr/course/Index.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        name: "course-home",
        component: () => import("../views/tr/course/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "konular",
        name: "course-lessons",
        component: () => import("../views/tr/course/Lessons.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "tartismalar",
        name: "course-discussions",
        component: () => import("../views/tr/course/discussions/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "tartismalar/:id",
        name: "course-discussion",
        component: () => import("../views/tr/course/discussions/View.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/en",
    component: () => import("../layouts/en/home/Index.vue"),
    children: [
      {
        path: "",
        name: "home-en",
        component: () => import("../views/en/home/Index.vue")
      },
      {
        path: "about-us",
        name: "about-us-en",
        component: () => import("../views/en/about-us/Index.vue")
      },
      {
        path: "course-catalog",
        name: "course-catalog-en",
        component: () => import("../views/en/course-catalog/Index.vue")
      },
      {
        path: "course-catalog/:courseUrlCode",
        name: "course-catalog-detail-en",
        component: () => import("../views/en/course-catalog/Detail.vue")
      }
    ]
  },
  {
    path: "",
    component: () => import("../layouts/en/account/Index.vue"),
    children: [
      {
        path: "/en/sign-up",
        name: "sign-up-en",
        component: () => import("../views/en/account/SignUp.vue")
      },
      {
        path: "/en/sign-in",
        name: "sign-in-en",
        component: () => import("../views/en/account/SignIn.vue")
      },
      {
        path: "/en/forgot-password",
        name: "forgot-password-en",
        component: () => import("../views/en/account/ForgotPassword.vue")
      }
    ]
  },
  {
    path: "/en/learner",
    component: () => import("../layouts/en/learner/Index.vue"),
    children: [
      {
        path: "",
        name: "learner-home-en",
        component: () => import("../views/en/learner/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "settings",
        name: "learner-settings-en",
        component: () => import("../views/en/learner/Settings.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/courses/:courseUrlCode",
    component: () => import("../layouts/en/course/Index.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        name: "course-home-en",
        component: () => import("../views/en/course/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "lessons",
        name: "course-lessons-en",
        component: () => import("../views/en/course/Lessons.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "discussions",
        name: "course-discussions-en",
        component: () => import("../views/en/course/discussions/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "discussions/:id",
        name: "course-discussion-en",
        component: () => import("../views/en/course/discussions/View.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/admin",
    component: () => import("../layouts/admin/Index.vue"),
    children: [
      {
        path: "",
        name: "admin-home",
        component: () => import("../views/admin/Index.vue")
      },
      {
        path: "kullanicilar",
        name: "admin-users",
        component: () => import("../views/admin/Users.vue")
      },
      {
        path: "egitimler",
        name: "admin-courses",
        component: () => import("../views/admin/courses/Index.vue")
      },
      {
        path: "egitimler/:id",
        name: "admin-course-dashboard",
        component: () => import("../views/admin/courses/Dashboard.vue")
      },
      {
        path: "egitimler/:id/lessons",
        name: "admin-course-lessons",
        component: () => import("../views/admin/courses/lessons/Index.vue")
      },
      {
        path: "sorular",
        name: "admin-questions",
        component: () => import("../views/admin/questions/Index.vue")
      }
    ]
  },
  // Test sayfaları
  {
    path: "/test/cregs",
    name: "test-cregs",
    component: () => import("../views/test/Cregs.vue")
  },
  {
    path: "*",
    name: "not-found",
    component: () => import("../views/404.vue")
  }
];

const userCanAccess = function(to: Route, isAdminRoute: boolean) {
  const userSignedIn = store.getters["auth/userSignedIn"];
  const user = store.getters["auth/user"];
  const authRole = to.meta.authRole;
  const isAdmin = userSignedIn ? user.roles.indexOf("admin") >= 0 : false;

  // Eğer Route /admin içindeyse authRole'a bakmadan
  // kullanıcının admin rolünde olup olmadığını sorgula
  if (isAdminRoute) {
    if (isAdmin) {
      return true;
    } else {
      return false;
    }
  }

  // Kullanıcı oturum açmış mı?
  if (userSignedIn) {
    // Route için bir rol belirlenmiş ise izin ver
    // Yoksa kullanıcının rolünü kontrol et
    if (!authRole) {
      return true;
    } else {
      // Admin ise izin ver
      if (isAdmin) {
        return true;
      }
      // Kullanıcı istenilen yetki rolünde mi?
      else {
        return user.roles.indexOf(to.meta.authRole.toLowerCase()) >= 0;
      }
    }
  } else {
    return false;
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { selector: to.hash };
    }

    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  const isAdminRoute = /^\/admin/.test(to.path);
  const requiresAuth =
    to.matched.some(record => record.meta.requiresAuth) || isAdminRoute;

  if (requiresAuth) {
    if (userCanAccess(to, isAdminRoute)) {
      next();
    } else {
      next({
        name: "sign-in",
        query: { returnUrl: to.path }
      });
    }
  } else {
    next();
  }
});

export default router;
