import Vue from "vue";
import Vuetify from "vuetify/lib";
import "fontsource-roboto";
import "fontsource-fira-sans";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import tr from "vuetify/src/locale/tr";

Vue.use(Vuetify);

const color1 = "#bede95";
const color2 = "#88afaf";
const color3 = "#555a6f";
const color4 = "#faf8f9";
const color5 = "#ff8800";

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: color1,
        secondary: color5,
        accent: color3,
        "soft-gray": color4
      },
      dark: {
        primary: color1,
        secondary: color2,
        accent: color3
      }
    }
  },
  lang: {
    locales: { tr },
    current: "tr"
  }
});
