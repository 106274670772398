import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth
  },
  state: {
    lang: "tr"
  },
  getters: {
    lang(state) {
      return state.lang;
    }
  },
  mutations: {
    setLang(state, value) {
      state.lang = value;
    }
  },
  actions: {
    lang(context) {
      return context.state.lang;
    }
  }
});
