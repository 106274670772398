<template>
  <div>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
    <notifications classes="notify" />
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo: {
    title: "Hoş geldiniz!",
    titleTemplate: "%s - Diji Vatandaş",
    htmlAttrs: { lang: "tr" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
};
</script>

<style lang="scss">
// vue-notification
.notify {
  padding: 10px;
  margin: 0 5px 5px;
  font-family: $body-font-family;
  font-size: 90%;
  color: $color4;
  background: $color3 !important;
  border-left: 5px solid $color4 !important;

  // .notification-title {
  //   // Style for title line
  // }

  // .notification-content {
  //   // Style for content
  // }

  &.warning {
    color: map-get($shades, "white");
    background: map-get($amber, "lighten-1") !important;
    border-left-color: map-get($amber, "darken-2");
  }

  &.error {
    color: map-get($shades, "white");
    background: map-get($red, "lighten-1") !important;
    border-color: transparent !important;
    border-left-color: map-get($red, "darken-4") !important;
  }

  &.success {
    color: map-get($shades, "white");
    background: map-get($green, "lighten-1") !important;
    border-color: transparent !important;
    border-left-color: map-get($green, "darken-2") !important;
  }
}
</style>
